<template>
  <div class="page-content">
    <Breadcrumb/>
    <SearchEngine
        v-if="isAdmin"
    />
    <router-view></router-view>
  </div>
</template>

<script>
  import Breadcrumb from '../../templates/Breadcrumb/Breadcrumb.vue'
  import SearchEngine from "../../coreComponents/SearchEngine/SearchEngine";

  export default {
    name: "InactiveFBMUsersModule",

    components:{
      SearchEngine,
      Breadcrumb,
    },

    data(){
      return {

      }
    },

    methods: {

    },

    mounted() {

    },

  }

</script>

<style lang="scss">

</style>
